import { force_login, identity } from './user';
import { load_page } from './ui';

let page_handlers = [];
let state_history = [];

const ROUTE_DEFAULT_OPTIONS = {
    loginRequired: true,
};

$(() =>
    {
        setup_singlepage_links();
        setup_statechange_hook();

        $(window).trigger('singlepagesetup');

        load_first_page();
    });

function get_base_url()
{
    let base = $('base:first').attr('href'),
        a = document.createElement('a');

    a.href = base;
    let baseUrl = a.href;

    return baseUrl;
}

function setup_singlepage_links()
{
    $('body').on('click', 'a[rel="internal"]', (e) =>
        {
            let baseUrl = get_base_url();

            let link = e.currentTarget,
                page = link.href.substr(baseUrl.length);

            navigate_to(page);

            return false;
        });
}

function navigate_to(page)
{
    if (window.location.href === get_base_url() + page) {
        // no-op if we're already on the requested page
        return;
    }
    push_state(page);
    if (state_history[1] && state_history[1].derived_from === page) {
        restore_page_icon();
        return;
    }
    load_content(page);
}

async function load_first_page()
{
    await identity;

    let baseUrl = get_base_url();

    let page = window.location.href.substr(baseUrl.length).replace(/\?.*/, '');

    state_history.unshift({page});
    load_content(page);
}

function add_route(pattern, callback, options)
{
    options = $.extend({}, ROUTE_DEFAULT_OPTIONS, options || {});
    page_handlers.push({pattern, callback, options});
}

function setup_statechange_hook()
{
    window.onpopstate = ev => {
        console.debug(ev);
        state_history.shift();
        if (ev.state && typeof(ev.state.page) === 'string') {
            load_content(ev.state.page);
        }
    };
}

async function derive_from(page)
{
    state_history[0].derived_from = page;
    console.debug(state_history);
    if (!state_history[1] || state_history[1].page !== page) {
        await load_content(page);
    }
}

function push_state(page)
{
    let histObj = {page};
    if (state_history[0] && state_history[0].derived_from === page) {
        for (const sh of state_history) {
            if (sh.page == state_history[0].derived_from) {
                histObj = sh;
                break;
            }
        }
    }
    state_history.unshift(histObj);
    window.history.pushState({page}, '', get_base_url() + page);
}

function compile_pattern(pattern)
{
    var type_matchers = {
        dns: '[a-z0-9-]+(?:\\.[a-z0-9-]+)*',
        guid: '[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}',
        slug: '[A-Za-z0-9_\\.-]+',
        str: '.+',
    };

    const meta_pattern = /:(?<param>[a-z0-9_]+)\((?<type>[a-z]+)\)/g;
    var matches,
        params = [],
        pattern_exp = pattern;

    while ((matches = meta_pattern.exec(pattern)) != null) {
        if (params.indexOf(matches.groups.param) > -1) {
            throw ("Parameter \"" + matches.groups.param + "\" declared twice in route pattern");
        }
        if (!type_matchers[matches.groups.type]) {
            throw ("Parameter \"" + matches.groups.param + "\": unknown type: " + matches.groups.type);
        }

        let subpattern = '(?<' + matches.groups.param + '>' +
            type_matchers[matches.groups.type] +
            ')';

        pattern_exp = pattern_exp.replace(matches[0], subpattern);
    }

    return new RegExp('^' + pattern_exp + '$');
}

async function load_content(page)
{
    $('a[rel="internal"]').removeClass('active');
    $('a[rel="internal"][href="' + page + '"]').addClass('active');
    activate_loading_spinner();

    let uri = page.replace(/\?.*$/, '');

    for (const h of page_handlers) {
        let regexp = compile_pattern(h.pattern),
            result = regexp.exec(uri);

        if (result !== null) {
            if (h.options.loginRequired) {
                if (!(await identity).identity) {
                    return await force_login();
                }
            }
            let page_result = h.callback(result.groups);
            if (page_result instanceof Promise) {
                return await page_result;
            }
            return page_result;
        }
    }

    load_page('404');
    return null;
}

function activate_loading_spinner()
{
    const $icon = $('.page-content h4:first .fas');

    if ($icon.hasClass('fa-circle-notch')) {
        return;
    }

    $icon.data('orig-class', $icon.attr('class'));
    $icon.attr('class', 'fas fa-fw fa-circle-notch fa-spin');
}

function restore_page_icon()
{
    const $icon = $('.page-content h4:first .fas'),
        classes = $icon.data('orig-class');

    if (typeof(classes) !== 'string') {
        return;
    }

    $icon.attr('class', classes);
    $icon.data('orig-class', null);
}

function parse_query()
{
    let result = {},
        qs = window.location.search.substr(1);

    for (const entry of qs.split('&')) {
        const eq = entry.indexOf('=');
        if (eq == -1) {
            result[entry] = true;
        }
        else {
            result[entry.substr(0, eq)] = decodeURIComponent(entry.substr(eq+1));
        }
    }

    return result;
}

export {
    get_base_url,
    navigate_to,
    derive_from,
    add_route,
    push_state,
    load_content,
    parse_query,
    state_history,
};
