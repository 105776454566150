import Swal from 'sweetalert2';

import 'datatables';
import { add_route } from './singlepage'
import { api } from './api';
import { action_button, load_template, load_page } from './ui';
import { nj } from './nunjucks-environment';

$(window).bind('singlepagesetup', function()
    {
        add_route('dns', async function(args)
            {
                const [nunjucks, page, domains, records] = await Promise.all([
                    nj(),
                    load_page('dns'),
                    api('GET', 'domains'),
                    api('GET', 'records'),
                ]);

                $('.table-dns').DataTable({
                    data: records,
                    columns: [
                        {
                            data: 'rname',
                        },
                        {
                            data: 'domain',
                            render: col => domains.reduce((t, d) => t + (d.id == col ? d.name : ''), ''),
                        },
                        {
                            data: 'type',
                        },
                        {
                            data: 'rdata',
                            render: col => nunjucks.render('dns/rdata', {rdata: col}),
                        },
                        {
                            orderable: false,
                            className: 'actions',
                            width: '0',
                            render: (cell, context, row) => {
                                let html = '';
                                html += action_button(
                                    'Edit Record',
                                    'pencil-alt',
                                    'warning',
                                    'btn-edit-client',
                                    `dns/record/${row.id}`
                                );

                                html += action_button(
                                    'Delete Record',
                                    'trash',
                                    'danger',
                                    'btn-delete-site',
                                    `dns/record/${row.id}/delete`
                                );

                                return html;
                            },
                        }
                    ]
                });
            });

        add_route('dns/record/:record(guid)/delete', async function(args)
            {
                await derive_from('dns');

                const record = await api('GET', `record/${args.record}`);
                const answer = await Swal.fire({
                    icon: 'exclamation',
                    title: `Delete the ${record.type} record "${record.rname}.${record.domain.name}"?`,
                    text: 'The record will be immediately removed from DNS.',
                    showCancelButton: true,
                    confirmButtonText: 'Confirm',
                });

                if (answer.isConfirmed) {
                    await api('DELETE', `record/${record.id}`);
                    alert_success(`Deleted record "${record.id}"`);
                }
                navigate_to('dns');
            });

        add_route('dns/record/create', async function(args)
            {
                const [domains, schema] = await Promise.all([
                    api('GET', 'domains'),
                    api('GET', 'dns/schema'),
                ]);

                const container = await load_template('dns/edit', {domains, schema, type: 'A', ttl: 3600});

                container.find('form[name="record"]').on('success', (event, result) => {
                    alert_success(`Created ${result.type} record ${result.rname}.${result.domain.name}.`);
                    navigate_to('dns');
                });

                setup_dns_form(container);
            });

        add_route('dns/record/:record(guid)', async function(args)
            {
                const [record, domains, schema] = await Promise.all([
                    api('GET', `record/${args.record}`),
                    api('GET', `domains`),
                    api('GET', 'dns/schema'),
                ]);
                record.domains = domains;
                record.schema = schema;
                const container = await load_template('dns/edit', record);

                container.find('form[name="record"]').on('success', (event, result) => {
                    alert_success(`Updated ${result.type} record "${result.rname}.${result.domain.name}".`);
                });

                setup_dns_form(container);
            });
    });

function setup_dns_form(container)
{
    const $form = container.find('form[name="record"]');

    $form.on('change', 'input:radio[name="type"]', event => {
        const rtype = $form.find('input:radio:checked[name="type"]').attr('value').toLowerCase();

        $form.find('.show-if-record-type-is.type-' + rtype).show()
            .find('input, select').prop('disabled', false);
        $form.find('.show-if-record-type-is:not(.type-' + rtype + ')').hide()
            .find('input, select').prop('disabled', true);
    });

    $form.find('input:radio:checked').trigger('change');
}