import Swal from 'sweetalert2';

import { alert_success } from './alert-banners';
import { add_route, derive_from, navigate_to } from './singlepage';
import { action_button, load_template, load_page } from './ui';
import { api } from './api';

$(window).bind('singlepagesetup', function()
    {
        add_route('sites', function(matches)
            {
                let page = load_page('sites'),
                    sites = api('GET', 'sites');

                Promise.all([page, sites]).then(values => {
                    let html = values[0],
                        sites = values[1];

                    $('.table-sites').DataTable({
                        data: sites,
                        columns: [
                            {
                                data: 'name',
                            },
                            {
                                data: 'asn',
                            },
                            {
                                data: 'id',
                                className: 'xm-col-guid',
                            },
                            {
                                orderable: false,
                                className: 'actions',
                                width: '0',
                                render: (cell, context, row) => {
                                    let html = '';
                                    html += action_button(
                                        'Edit Site',
                                        'pencil-alt',
                                        'warning',
                                        'btn-edit-site',
                                        'site/' + row.id
                                    );

                                    html += action_button(
                                        'Delete Site',
                                        'trash',
                                        'danger',
                                        'btn-delete-site',
                                        `site/${row.id}/delete`
                                    );

                                    return html;
                                },
                            }
                        ]
                    });
                });
            });

        add_route('site/create', async function(args)
            {
                const container = await load_template('site/edit');

                container.find('form[name="site"]').on('success', (event, result) => {
                    alert_success(`Successfully created the site "${result.name}"`);
                    navigate_to(`site/${result.id}`);
                });
            });

        add_route('site/:id(guid)', async function(args)
            {
                const site = await api('GET', `site/${args.id}`);
                const container = await load_template('site/edit', site);

                container.find('form[name="site"]').on('success', (event, result) => {
                    alert_success(`Updated site "${result.name}"`);
                });
            });

        add_route('site/:id(guid)/delete', async function(args)
            {
                await derive_from('sites');

                const site = await api('GET', `site/${args.id}`);

                const answer = await Swal.fire({
                    title: `Delete site "${site.name}"?`,
                    text: 'Deleting this site will remove all of the domains, ranges, and reservations associated with it. This is an extremely destructive operation.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Delete Site',
                });

                if (answer.isConfirmed) {
                    await api('DELETE', `site/${site.id}`);
                    alert_success(`Deleted site "${site.name}".`);
                }

                navigate_to('sites');
            });
    });