import Swal from 'sweetalert2';
import moment from 'moment';
import 'select2';
import 'timeago';

import { get_base_url } from './singlepage';
import { api } from './api';
import { nj } from './nunjucks-environment';

const keyexpr = /^[A-Za-z0-9_-]+(\[[A-Za-z0-9 _-]+\])*(\[\])?$/;

// CSS classes for DataTables form elements
$(() => {
    jQuery.extend(jQuery.fn.dataTableExt.oStdClasses, {
        sFilterInput: "form-control",
        sLengthSelect: "form-control",
    });
});

// Click handler for modals
$(() => {
    $('body').on('click', 'a', e =>
        {
            let $self = $(e.currentTarget);
            if (!$self.data('display') || $self.data('display') != 'modal') {
                return;
            }

            let href = e.currentTarget.hash,
                modal = $(href);

            if (modal && modal.hasClass('modal')) {
                modal.bind('shown.bs.modal', () => {
                    var inp = $('input, select, textarea', modal)[0];
                    $(inp).focus();
                });
                modal.modal('show');
                return false;
            }
            return false;
        });
});

// Promise to load a page
function load_page(page_name)
{
    if ($('.page-content').data('loaded-page') === page_name) {
        return new Promise((resolve, reject) => {
            resolve($('.page-content'));
        });
    }
    let url = get_base_url() + 'pages/' + page_name + '.html';

    return new Promise((resolve, reject) => {
        fetch(url)
            .then(resp => resp.text())
            .then(resp => {
                $('.page-content').html(resp).data('loaded-page', page_name);
                $('.page-content').trigger('content-load');
                resolve($('.page-content'));
            });
    });
}

// Load a page using a nunjucks template
async function load_template(template_name, ctx)
{
    const html = (await nj()).render(template_name, ctx);
    $('.page-content').html(html).data('loaded-page', 'template/' + template_name);
    $('.page-content').trigger('content-load');
    return $('.page-content');
}

// Generate a button for inclusion in the "actions" column of a table.
function action_button(label, icon, color_class, css_classes, href)
{
    css_classes = css_classes || [];
    if (typeof(css_classes) == 'string') {
        css_classes = css_classes.split(/\s+/);
    }

    let container = $('<span />'),
        btn = $('<a />')
                .addClass(['btn', 'btn-sm', 'btn-' + color_class]);

    btn.append($('<i />').addClass(['fas', 'fa-' + icon]));
    btn.attr('title', label);

    if (href) {
        btn.attr('rel', 'internal');
        btn.attr('href', href);
    }

    container.append(btn);
    return container.html();
}

// Helpers for serializing HTML forms with PHP-style deep nested object syntax
// into JSON objects.
function extend_data(data, parts, value)
{
    if (parts.length == 1) {
        if (parts[0] == '') {
            data.push(value);
        }
        else {
            data[parts[0]] = value;
        }
    }
    else {
        if (!data[parts[0]]) {
            if (parts[1] == '') {
                data[parts[0]] = [];
            }
            else {
                data[parts[0]] = {};
            }
        }
        data[parts[0]] = extend_data(data[parts[0]], parts.slice(1), value);
    }
    return data;
}

function extend_form_data(data, key, value)
{
    if (!keyexpr.test(key)) {
        throw ("Key \"" + key + "\" cannot be serialized to JSON");
    }
    let parts = key.split(/[\[\]]/),
        parts_filtered = [parts[0]];
    for (let i = 1; i < parts.length; i += 2) {
        parts_filtered.push(parts[i]);
    }

    data = extend_data(data, parts_filtered, value);

    return data;
}

function find_empty_checkbox_arrays(form)
{
    let data = {};

    // special case for checkboxes - ensure an empty set of checkboxes gets
    // turned into an empty array
    $(form).find('input:checkbox:not(:checked)').each((i, e) => {
        let key = $(e).attr('name');
        if (!keyexpr.test(key)) {
            throw ("Key \"" + key + "\" cannot be serialized to JSON");
        }
        let parts = key.split(/[\[\]]/),
            parts_filtered = [parts[0]],
            node = data;
        for (let i = 1; i < parts.length; i += 2) {
            if (parts[i] !== '') {
                parts_filtered.push(parts[i]);
            }
        }
        let nextChild = data,
            lastPart = null;
        for (const part of parts_filtered) {
            node = nextChild;
            if (!(part in node)) {
                node[part] = {};
            }
            nextChild = node[part];
            lastPart = part;
        }
        if ($.isEmptyObject(node[lastPart])) {
            node[lastPart] = [];
        }
    });

    return data;
}

function find_empty_textbox_arrays(form)
{
    let data = {};

    $(form).find('input.scan-empty:hidden').each((i, e) => {
        let elname = $(e).attr('value') + '[]';
        if ($(form).find('input[name="' + elname + '"]').length < 1) {
            data[$(e).attr('value')] = [];
        }
    });

    return data;
}

// Submit handler for API forms
$(() => {
    jQuery.fn.extend({
        serializeJSON: function() {
            let result = {};
            this.each(function() {
                let data = $(this).serializeArray();
                for (let i = 0; i < data.length; i++) {
                    result = extend_form_data(result, data[i].name, data[i].value);
                }
                result = $.extend(find_empty_checkbox_arrays(this),
                                find_empty_textbox_arrays(this),
                                result);
            });
            return result;
        },
        restoreState: function(state) {
            for (var i in state) {
                if (typeof(state[i]) == 'object') {
                    // unhandled at present
                }
                else if (typeof(state[i]) == 'string') {
                    this.find('input[name="' + i + '"]').val(state[i]);
                }
            }

            return this;
        },
    });

    $('body').on('submit', 'form', e =>
        {
            let $self = $(e.currentTarget);
            if (!$self.data('transport') || $self.data('transport') != 'api') {
                return;
            }

            try {
                let data = $self.serializeJSON(),
                    method = $self.attr('method').toUpperCase(),
                    target = $self.attr('action');

                delete data.id;

                let event = jQuery.Event('beforesubmit', {formData: data, method, target});
                $self.trigger(event);

                api(method, target, data)
                    .then(resp => {
                        $self.trigger('success', resp);
                    });
            }
            catch(e) {
                console.error(e);
                Swal.fire({
                    type: 'error',
                    title: 'Form serialization failed',
                    text: 'Something went wrong while serializing the form ' +
                        'data in prep for sending it to the server.'
                });
                return false;
            }

            return false;
        });

    // Ability multi-edit controls
    $('.page-content').on('click', '.oauth-abilities .btn-delete-ability', event => {
        $(event.currentTarget).parents('.ability-entry:first').remove();
    });
    $('.page-content').on('click', '.oauth-abilities .btn-add-ability', async (event) => {
        const $parent = $(event.currentTarget).parents('.oauth-abilities:first');
        const field_name = $parent.data('field-name');
        const html = (await nj()).render('bits/ability', {'ability': '', 'field_name': field_name});
        $parent.find('> :last-child')
            .before(html);
    });
});

// Render handler for when the main page content changes
$(() => {
    jQuery.fn.extend({
        prettyTimestamp: function()
            {
                this.each((i, e) => {
                    let ts = new Date(parseInt($(e).data('timestamp')) * 1000),
                        m = moment(ts);
                    let time_element = $('<time />').attr('datetime', ts.toISOString());
                    if ($(e).hasClass('only')) {
                        $(e).empty()
                            .append(time_element);
                    }
                    else {
                        $(e).empty()
                            .append(m.format('dddd, MMMM D YYYY, H:mm'))
                            .append(' (')
                            .append(time_element)
                            .append(')');
                        time_element.addClass('text-muted');
                    }
                    time_element.timeago();
                });
            },
        });
    $('.page-content').on('content-load', function()
        {
            // Setup .timeago elements
            $('.timeago', this).prettyTimestamp();
        });
});

// jQuery plugin for XM user inputs
$(() => {
        $.fn.select2.defaults.set( "theme", "bootstrap" );

        jQuery.fn.extend({
            userInput: function() {
                this.select2({
                    ajax: {
                        delay: 200,
                        url: function(params) {
                            let term = typeof(params.term) == 'string' ? params.term : '';
                            return `/api/user/search/${term}`;
                        },
                        dataType: 'json',
                        processResults: function (resp) {
                            let results = [];
                            for (const r of resp) {
                                results.push({
                                    id: r.id,
                                    text: r.principal,
                                });
                            }
                            return {results};
                        },
                    },
                });
                return this;
            },
        });
});

// jQuery outerHTML and appendText functions
$(() => {
    jQuery.fn.extend({
        outerHTML: function()
            {
                return this.get(0).outerHTML;
            },
        appendText: function(text)
            {
                this.each((i, e) => {
                    e.appendChild(document.createTextNode(text));
                });

                return this;
            }
    });
});

export {
    load_page,
    load_template,
    action_button,
};
