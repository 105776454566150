import { add_route, navigate_to } from './singlepage'
import { api } from './api';
import { load_template } from './ui';

$(window).bind('singlepagesetup', async function()
    {
        add_route('search/:term(str)', async function(args)
            {
                let results = await api('POST', 'search', {term: args.term});

                await load_template('search/results', {term: args.term, results});
            });

        $('form[name="search"]').on('submit', function()
            {
                let term = $(this).find('input[name="term"]').val();
                navigate_to(`search/${term}`);

                return false;
            });

        let identity = await window.identity;
        if (!('read' in identity.abilities) && !('superadmin' in identity.abilities)) {
            //$('form[name="search"]').hide();
        }
    });
