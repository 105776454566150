import Swal from 'sweetalert2';

let baseUrl = `${window.location.protocol}//${window.location.host}/api`;

class ApiOptions {
    handleError(e) {
        console.debug(e);
        if (typeof(e) === 'object' && e.message) {
            Swal.fire({
                type: 'error',
                title: 'Unable to complete action',
                text: e.message,
            });
        }
        else {
            Swal.fire({
                type: 'error',
                title: 'API call failed',
                text: 'Something went wrong during an API call to the ' +
                    'server. We\'ve made a note of this error.'
            });
        }
    }
}

class ApiOptionsSilentError extends ApiOptions {
    handleError(e) {}
}

const api = (method, uri, body, opts) => {
    opts = opts || new ApiOptions();

    if (uri.charAt(0) != '/') {
        uri = '/' + uri;
    }

    let fetchOpts = {method};
    if (['PUT', 'POST', 'PATCH'].indexOf(method) > -1) {
        fetchOpts.body = JSON.stringify(body);
        fetchOpts.headers = {
            'Content-Type': 'application/json'
        };
    }

    let req = new Request(baseUrl + uri, fetchOpts);
    return new Promise((resolve, reject) => {
        fetch(req)
            .then(resp => {
                if (!resp.ok) {
                    return resp.json().then(j => { throw(j) });
                }
                return resp.json();
            })
            .then(resolve)
            .catch((e) => {
                opts.handleError(e);
                reject(e);
            });
    });
};

export {
    baseUrl,
    ApiOptions,
    ApiOptionsSilentError,
    api,
};