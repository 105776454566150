import { add_route } from "./singlepage";
import { load_page } from "./ui";
import markdownit from 'markdown-it';

$(window).bind('singlepagesetup', function()
    {
        add_route('page/:page(slug)', async function(args)
            {
                let response = await fetch(`/pages/${args.page}.md`),
                    status = response.status,
                    contents = await response.text();

                if (status == 200) {
                    const md = markdownit()
                    $('.page-content').html(
                        md.render(contents)
                    );
                } else if (status == 404) {
                    load_page('404');
                    return;
                } else {
                    $('.page_content').clear().append(
                        $('<h2 />').text(
                            `Error: ${status} ${response.statusText}`
                        )
                    );
                }
            }, {loginRequired: false});
    });