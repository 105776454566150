import Swal from 'sweetalert2';
import moment from 'moment';

import { add_route, derive_from, navigate_to } from "./singlepage";
import { action_button, load_page, load_template } from "./ui";
import { api } from "./api";
import { alert_success } from "./alert-banners";

$(window).bind('singlepagesetup', function()
    {
        add_route('domains', async function(matches)
            {
                let [page, sites, domains] = await Promise.all([
                    load_page('domains'),
                    api('GET', 'sites'),
                    api('GET', 'domains'),
                ]);

                $('.table-domains').DataTable({
                    data: domains,
                    pageLength: 50,
                    columns: [
                        {
                            data: 'site',
                            render: col => sites.reduce((t, s) => t + (s.id == col ? s.name : ''), ''),
                        },
                        {
                            data: 'name',
                        },
                        {
                            data: 'vlan_id',
                        },
                        {
                            data: 'inet4_address',
                            render: (cell, context, row) => `${cell}/${row.inet4_prefixlen}`,
                        },
                        {
                            data: 'inet6_address',
                            render: (cell, context, row) => `${cell}/${row.inet6_prefixlen}`,
                        },
                        {
                            orderable: false,
                            className: 'actions',
                            width: '0',
                            render: (cell, context, row) => {
                                let html = '';
                                html += action_button(
                                    'Edit Domain',
                                    'pencil-alt',
                                    'warning',
                                    'btn-edit-domain',
                                    'domain/' + row.name
                                );
                                if (row.guest_password && row.features.indexOf('captive_portal') >= 0) {
                                    html += action_button(
                                        'View Guest Password',
                                        'key',
                                        'inverse',
                                        'btn-view-guest-pw',
                                        `domain/${row.id}/guestpw`
                                    );
                                }
                                html += action_button(
                                    'Delete Domain',
                                    'trash',
                                    'danger',
                                    'btn-delete-host',
                                    'domain/' + row.id + '/delete',
                                );

                                return html;
                            },
                        }
                    ]
                });
            });

        add_route('domain/create', async function(args)
            {
                const sites = await api('GET', 'sites');

                const container = await load_template('domain/edit', {sites});

                container.find('form[name="domain"]').on('success', (event, result) => {
                    alert_success(`Successfully created the domain "${result.name}".`);
                    navigate_to(`domain/${result.id}`);
                });
            });

        add_route('domain/:id(guid)', async function(args)
            {
                const [sites, domain, ranges] = await Promise.all([
                        api('GET', 'sites'),
                        api('GET', `domain/${args.id}`),
                        api('GET', `domain/${args.id}/ranges`),
                ]);
                domain.ranges = ranges;
                domain.sites = sites;

                const container = await load_template('domain/edit', domain),
                    $form = container.find('form[name="domain"]');

                $form.on('beforesubmit', event => {
                    console.debug('domain form submit: event:', event);
                    if (event.formData.dns_server_v4 == '') {
                        event.formData.dns_server_v4 = null;
                    }

                    if (event.formData.dns_server_v6 == '') {
                        event.formData.dns_server_v6 = null;
                    }
                });
                $form.on('success', (event, result) => {
                    alert_success(`Successfully saved the domain "${result.name}".`);
                    navigate_to(`domain/${result.id}`);
                });
            });

        add_route('domain/:domain(dns)', async function(args) {
            const domain = await api('GET', `domain/${args.domain}`);

            await derive_from(`domain/${domain.id}`);
        });

        add_route('domain/:id(guid)/delete', async function(args)
            {
                await derive_from('domains');

                const domain = await api('GET', `domain/${args.id}`);
                let answer = await Swal.fire({
                        title: `Delete domain "${domain.name}"?`,
                        text: 'Deleting this domain will remove all of the ranges and reservations in it. The zone will be removed from DNS. This is a destructive, irreversible operation. Continue?',
                        icon: 'warning',
                        showCancelButton: true,
                });

                if (answer.isConfirmed) {
                    await api('DELETE', `domain/${domain.id}`);
                    alert_success(`Deleted domain "${domain.name}".`);
                }
                navigate_to('domains');
            });

        add_route('domain/:id(guid)/guestpw', async function(args)
            {
                await derive_from('domains');

                let today = moment().format('YYYY-MM-DD');
                const gpw = await api('GET', `domain/${args.id}/guestpw/${today}`);
                let timeout = null;

                let swal = Swal.fire({
                        title: gpw.guest_password,
                        input: 'date',
                        inputPlaceholder: 'YYYY-MM-DD',
                        inputValue: today,
                        html: `Copy and share the guest password for "${gpw.domain.name}".<br /><br />` +
                            `Guest passwords change daily at midnight.<br /><br />` +
                            `All machines owned by guests are deleted after they have not been seen on the network for 24 hours.`,
                        didOpen: () => {
                            const $swal = $(Swal.getPopup()),
                                $title = $swal.find('.swal2-title'),
                                $date = $swal.find('input[type="date"]');

                            $date.on('change', async (event) => {
                                if (timeout !== null) {
                                    clearTimeout(timeout);
                                    timeout = null;
                                }
                                timeout = setTimeout(async () => {
                                    let value = $date.val();
                                    if (!/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(value)) {
                                        return;
                                    }
                                    let resp = await api('GET', `domain/${args.id}/guestpw/${encodeURIComponent(value)}`, null, );

                                    $title.text(resp.guest_password);
                                }, 400);
                            });
                        },
                });

                await swal;

                navigate_to('domains');
            });
    });
