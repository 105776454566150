import { add_route } from './singlepage';
import { bind_mqtt_event } from './events';
import { load_page } from './ui';
import { api } from './api';
import { nj } from './nunjucks-environment';

let activityFeedObjectCache = {
    'host': {},
    'domain': {},
    'iface': {},
    'mdm_command': {},
}

$(window).bind('singlepagesetup', function()
    {
        add_route('', async function(args)
            {
                await load_page('index');

                bind_mqtt_event({"event": "*"}, async (msg) => {
                    let now = Math.floor(((new Date()).getTime())/1000);
                    render_feed_event(msg, now);
                });

                let events = await api('GET', 'events/last');
                for (const ev of events) {
                    render_feed_event(ev['message'], ev['timestamp']);
                }
            });
    });

async function render_feed_event(msg, timestamp)
{
    let tagFetches = {};

    for (const k of Object.keys(msg.tags || {})) {

        if (typeof(activityFeedObjectCache[k]) !== 'object') {
            continue;
        }

        tagFetches[k] = (async (k, v) => {
            if (typeof(v) !== 'string' || !/^[a-f0-9]{8}-([a-f0-9]{4}-){3}[a-f0-9]{12}$/.test(v)) {
                return null;
            }
            if (typeof(activityFeedObjectCache[k][v]) !== 'undefined') {
                return activityFeedObjectCache[k][v];
            }
            let cacheKey = `${k}/${v}`,
                cacheEntry = window.sessionStorage.getItem(cacheKey)

            if (typeof(cacheEntry) === 'string') {
                return JSON.parse(cacheEntry);
            }
            let answer = await api('GET', cacheKey, null, {handleError: (e) => {}});
            window.sessionStorage.setItem(cacheKey, JSON.stringify(answer));

            activityFeedObjectCache[k][v] = answer;

            return answer;
        })(k, msg.tags[k]);
    }

    let ctx = {
        'event': msg,
        'now': timestamp,
        'raw_event': JSON.stringify(msg, null, 4),
    };

    for (const [k, v] of Object.entries(tagFetches)) {
        ctx[k] = await v;
    }

    $('.activity-feed-events').prepend(
        (await nj()).render('bits/feed-entry', ctx)
    );

    $('.activity-feed-events').find('.timeago').prettyTimestamp();
};